import "./AdmissionPage.css";
import { admissionProcess } from "../data/ApplicationData";
// import AccApp from "../Accordion/AccApp";
// import Accordion from "../Accordion/AccApp";
// import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";

function Admissions() {
  return (
    <section className="admission-page">
      <div className="admission-header">
        <div className="admission-header-container">
          <h1>Admissions</h1>
          <h2>Learn about the entire admissions process for each level.</h2>
        </div>
      </div>

      <div className="admission-main">
        <NewAdmission />
        <article>{/* <Accordion data={admissionProcess} /> */}</article>
        <p className="footnote">
          <em>
            This detailed admissions process outlines the steps and requirements
            for each level, ensuring that both parents and pupils have a clear
            understanding of what is expected during the enrollment process at
            Gateway School. It's important to note that these processes can be
            updated to reflect the specific policies and procedures of our
            school.
          </em>
        </p>
      </div>
    </section>
  );
}

export default Admissions;

export function NewAdmission() {
  const academicYearStart = `9<sup>th</sup> September, 2024`;
  const documentURL = "/public/testdoc.docx";

  return (
    <section className="new-admission-main">
      <header className="text-center mb-8">
        <h1 className="text-lg mb-2 font-bold ">
          IMPORTANT SCHOOL NOTICES FOR NEW REGISTRATION
        </h1>
        <h3 className="text-md mb-2">September 2024/2025 Academic Year</h3>
        <p className="text-sm">
          Office Hours (During Holiday Break) — 9 am - 4:30 pm
        </p>
      </header>

      <section className="new-admission">
        <ul>
          <article>
            <Accordion defaultIndex={[1]} allowMultiple>
              <AccordionItem>
                <h2 className="px-0">
                  <AccordionButton>
                    <Box
                      as="span"
                      flex="1"
                      textAlign="center"
                      fontWeight="bold"
                      fontSize="1.2rem"
                    >
                      Registration Steps
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <ul className="registration-steps">
                    <li>
                      <span>Payment</span>Please make the required payment at
                      the designated bank and provide the payment slip at the
                      school.
                    </li>
                    <li>
                      <span>Obtain Registration Form</span>After payment, the
                      registration form will be provided to you at the school
                      premises.
                    </li>
                    <li>
                      <span>Fill Out Form</span>Complete the registration form
                      with all required details.
                    </li>
                    <li>
                      <span>Submit Written Portion</span>Bring the completed
                      portion of the form to the school premises.
                    </li>
                    <li>
                      <span>Retain Information Sheets</span>Please keep the
                      information sheets for your records.
                    </li>
                  </ul>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </article>

          <article className="phone-info">
            <Accordion defaultIndex={[1]} allowMultiple>
              <AccordionItem>
                <h2 className="pb-4">
                  <AccordionButton>
                    <Box
                      as="span"
                      flex="1"
                      textAlign="center"
                      fontWeight="bold"
                      fontSize="1.2rem"
                    >
                      General Information
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <GeneralInfo />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </article>

          <article className="laptop-info">
            <GeneralInfo />
          </article>
        </ul>
        <ul>
          <div className="fee-details shadow-sm w-full">
            <h1 className="text-center text-2xl mb-4 font-semibold">
              Fee Details
            </h1>
            <li>
              <strong>REGISTRATION FEE</strong> — <strong>Le500</strong> (To be
              paid at bank. Payment is non-refundable)
            </li>
            <li>
              <strong>REQUIREMENT FOR NEW PUPILS</strong>
              <ul className="requirements">
                <li>One Passport-sized photo</li>
                <li>Photocopy of birth certificate</li>
                <li>
                  Photocopy of Last School Report Card (If applicable to Nursery
                  1)
                </li>
              </ul>
              <ol className="requirement-steps-sidebar">
                <h2 className="text-lg mb-2 font-semibold">Steps:</h2>
                <li>Make Payment</li>
                <li>Obtain Registration Form</li>
                <li>Fill Out Form</li>
                <li>Submit Written Portion</li>
                <li>Retain Information Sheets</li>
              </ol>
            </li>
            <section className="bg-gray-100 rounded-xl px-4 py-4 mb-8">
              <h2 className="text-lg mb-2 font-semibold">
                Admission Form and High School Fee Payment Details
              </h2>
              <ul className="account-details">
                <li>
                  <span>Bank Name</span> <span>:</span>{" "}
                  <span>Commerce and Mortgage Bank</span>
                </li>
                <li>
                  <span>Account</span> <span>:</span>{" "}
                  <span>Magstine Enterprise</span>
                </li>
                <li>
                  <span>Account. No.</span> <span>:</span>{" "}
                  <span>
                    {" "}
                    <strong>0021164771366</strong>
                  </span>
                </li>
                <li>
                  <span>Address</span> <span>:</span>{" "}
                  <span>
                    Liverpool Street, Off Siaka Stevens Street, Freetown
                  </span>
                </li>
              </ul>
            </section>
            <li>
              <strong>PAYMENT OF SCHOOL FEES AND CHARGES</strong>
              <p>
                All new pupils <strong>MUST</strong> pay the complete tuition
                fees and charges for first term before the commencement of
                classes at the respective bank accounts. Pupils will not be
                allowed in class if complete payment of fees and charges are not
                made.
              </p>
              <p className="py-2 px-4 bg-yellow-200 rounded-2xl mt-4 mb-8">
                All fees <strong>MUST</strong> be paid at{" "}
                <strong>ROKEL COMMERCIAL BANK</strong> at any of its branches.
              </p>

              <section className="bg-blue-100 rounded-xl px-4 py-4">
                <h2 className="text-lg mb-4 font-semibold">
                  Primary School Payment Details
                </h2>
                <ul className="account-details">
                  <li>
                    <span>Bank Name</span> <span>:</span>{" "}
                    <span>Rokel Commercial Bank</span>
                  </li>
                  <li>
                    <span>Account</span> <span>:</span>{" "}
                    <span>Gateway Preparatory School</span>
                  </li>
                  <li>
                    <span>Account. No.</span> <span>:</span>{" "}
                    <span>
                      {" "}
                      <strong>1419184</strong>
                    </span>
                  </li>
                  <li>
                    <span>Address</span> <span>:</span>{" "}
                    <span>Siaka Stevens Street, Freetown</span>
                  </li>
                </ul>
              </section>
            </li>
            <li className="fee-breakdown">
              <h2>Fee Breakdown</h2>
              <ul>
                <li>Tuition fees</li>
                <li>PTA</li>
                <li>Development Fund</li>
                <li>Text Books/files</li>
                <li>Assorted Stationeries</li>
                <li>Prize Giving and Carol Service</li>
                <li>Talent Show (One Ticket)</li>
                <li>Sightseeing</li>
                <li>Annual Sports</li>
                <li>Thanksgiving</li>
                <li>Outing/Family Day (One Ticket)</li>
                <li>Toiletries</li>
                <li>Day of The African Child</li>
                <li>End of Year Party</li>
              </ul>
            </li>
            {/* Holiday School Information */}
            <p className="bg-gray-200 px-4 py-4 rounded-xl mb-8">
              <h2 className="text-xl mb-4 font-semibold">Holiday Classes</h2>
              <strong>
                All new pupils MUST attend <em>Holiday Classes</em> and entry
                examination will be conducted at the end of August.
              </strong>
              <ul className="my-4">
                <li>
                  Period: 29<sup>th</sup> July — 22<sup>nd</sup> August
                </li>
                <li>Days: Monday to Thursday</li>
              </ul>
              <p>
                <em>Contact school for more details</em>
                <p>-- 079117795</p>
                <p>-- 076824084</p>
                <p>-- 078169527</p>
              </p>
            </p>

            <img
              src="/Holiday Classes Web.jpg"
              alt="holiday classes"
              className="rounded-xl w-full"
            />
          </div>

          <hr />

          {/* <DownloadDocument /> */}
        </ul>
      </section>
    </section>
  );
}

export const DownloadDocument = () => {
  const documentUrl = "/testdoc.docx";

  return (
    <div className="flex justify-center flex-col gap-4">
      {/* <h2>Download the Document</h2> */}
      <p>Download the form below and fill out the required details.</p>
      <a
        href={documentUrl}
        download="document.docx"
        className="bg-blue-500 py-2 px-4 text-center text-white rounded-md"
      >
        Download Registration Form
      </a>
      <p>After completion, return to school with the bank payment slip.</p>
    </div>
  );
};

export const GeneralInfo = () => {
  return (
    <section>
      <li>
        <strong>NEW ACADEMIC YEAR</strong>
        <p>
          School reopens on{" "}
          <strong>
            9<sup>th</sup> September 2024
          </strong>
        </p>
      </li>
      <li>
        <strong>SCHOOL TIME</strong>
        <ul className="school-time">
          <li>
            Daycare<span>-</span> <span>7:00 am - 5:00 pm</span>
          </li>
          <li>
            Nursery - Reception <span>-</span> <span>8:00 am - 1:30 pm</span>{" "}
          </li>
          <li>
            Classes One to Three <span>-</span> <span>8:00 am - 2:00 pm</span>
          </li>
          <li>
            Classes Four to Six <span>-</span> <span>8:00 am - 4:00 pm </span>
          </li>
          <li>
            JSS 1 to 3<span>-</span> <span>8:00 am - 4:30 pm</span>
          </li>
        </ul>
        <em>*Lesson hours included for classes 4 - 6.</em>
      </li>
      <li>
        <strong>TEXT BOOKS</strong>
        <p>
          With the exception of{" "}
          <strong>
            <u>Dictionaries, Bibles and Geometry sets</u>
          </strong>
          , text books will be provided by the school. Parents and guardians are
          required to provide the underlined when requested.
        </p>
      </li>
      <li>
        <strong>
          TEXT BOOK ALLOCATION (Not applicable to Nurseries 1 & 2)
        </strong>
        <p>
          Only pupils who have paid their complete fees and charges, for the
          first term,{" "}
          <strong>
            on or before 1<sup>st</sup> September, 2024
          </strong>{" "}
          will be allocated text books.
        </p>
      </li>
      <li>
        <strong>EXERCISE BOOKS</strong>
        <p>
          Exercise books are provided for pupils in Nursery 1 to Reception.
          Parents should provide exercise books for pupils in{" "}
          <strong>Class 1 to Class 3</strong> and ledgers for{" "}
          <strong>Classes 4 to JSS 3</strong> as per number of subjects offered.
        </p>
      </li>
      <li>
        <strong>HOME WORK BOOK</strong>
        <p>
          Pupils from Classes 1-6 should bring along 1 big extra book as
          homework book.
        </p>
      </li>
      <li>
        <strong>TEST AND EXAMINATIONS</strong>
        <p>
          Three Mid Term Tests and Three End of Term Examinations are conducted
          throughout the academic year.
        </p>
      </li>

      {/* School Uniform */}
      <li>
        <strong>SCHOOL UNIFORM</strong>
        <ul>
          <li>Ready-made uniforms are sold in School.</li>
          <li>
            Uniforms will only be made available to pupils who have paid their
            complete fees.
          </li>
        </ul>
      </li>
      <li>
        <strong>UNIFORM DAYS</strong>
        <p>
          Pupils wear daily school uniform three times a week and as follows:
        </p>
        <ul>
          <li>
            Monday to Wednesday <span>-</span> <span>Daily school Uniform</span>
          </li>
          <li>
            Thursday <span>-</span>{" "}
            <span>
              The blue <strong>school t-shirt</strong> is worn by all pupils
              (Daycare to Prep 6)
            </span>
          </li>
          <li>
            Friday <span>-</span> <span>Physical Education (PE) Day.</span>
            <p>
              The <strong>house colour t-shirt</strong> is worn with a short{" "}
              <strong>
                <u>navy blue trouser for boys:</u>
              </strong>{" "}
              preferably the school shorts. Girls wear{" "}
              <strong>
                <u>blue shorts</u>
              </strong>
            </p>
          </li>
        </ul>
      </li>
      <li>
        <strong>SCHOOL BADGES</strong>
        <ul>
          <li>
            All uniforms should be bought from the school.{" "}
            <strong>
              <u>School badges are not sold out.</u>
            </strong>
          </li>
        </ul>
      </li>
      <li>
        <strong>FOOT WEAR/SOCKS</strong>
        <ul>
          <li>
            <strong>Shoes:</strong> Black footwear for both boys and girls. (
            <strong>Coloured shoes</strong> are NOT allowed on uniform days. )
          </li>
          <li>
            <strong>Boys:</strong> Navy Blue Socks - Monday to Thursday (
            <strong>Nursery to Class 6</strong>)
          </li>
          <li>
            <strong>Girls: </strong> Navy Blue Socks - Monday to Thursday (
            <strong>Nursery to Class 6</strong>)
          </li>
          <li>
            <strong>Boys & Girls: </strong> Grey Socks - Monday to Thursday (
            <strong>JSS 1 - 3</strong>)
          </li>
          <li>
            <strong>P.E. Day: Sport shoes</strong> should be worn on Fridays for
            P.E. exercises. Pupils should put on white or coloured socks.
          </li>
        </ul>
      </li>
      <li>
        <strong>CEREMONIAL UNIFORM</strong>
        <ul>
          <li>
            Worn on special occassions such as prize giving, thanksgiving, carol
            service, and on any other occassion that management might deem
            necessary.
          </li>
          <li>
            <p>Ceremonial Uniform Accessories include:</p>
            <ul>
              <li>
                <strong>Girls:</strong> White dress, neck tie, belt, blue felt
                hat and band
              </li>
              <li>
                <strong>Boys:</strong> White long-sleeved shirt, long navy blue
                trousers, tie and cap.
              </li>
              <li>Black shoes are worn with the Ceremonial Uniform</li>
            </ul>
          </li>
        </ul>
        <p></p>
      </li>

      <li>
        <strong>SCHOOL ACTIVITIES</strong>
        <ul>
          <li>
            <strong>
              <u>First Term</u>
            </strong>
            <ul>
              <li>Annual Prize Giving</li>
              <li>Annual Carol Service</li>
              <li>Inter House Quiz Competition (Once Every Term)</li>
              <li>Christmas Talent Show</li>
              <li>Culture Day (To be determined)</li>
            </ul>
          </li>
          <li>
            <strong>
              <u>Second Term</u>
            </strong>
            <ul>
              <li>Annual School Sports</li>
              <li>Annual Thanksgiving Service</li>
              <li>Annual School Family Day Outing</li>
            </ul>
          </li>
          <li>
            <strong>
              <u>Third Term</u>
            </strong>
            <ul>
              <li>
                Commemorating the Day of the African Child (Bring food and
                Share)
              </li>
              <li>
                Annual Graduation — Reception, Prep 6 (Leaver's Service - JSS 3)
              </li>
              <li>
                Outside School Activities{" "}
                <strong>
                  (We participate in drama and quiz competitions on invitation),
                  end of year party.
                </strong>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </section>
  );
};
