import "./Contact.css";
import {
  FaPhoneAlt,
  FaLocationArrow,
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaVoicemail,
} from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import { BiMailSend, BiTimeFive } from "react-icons/bi";
import AccApp from "../Accordion/AccApp";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import Accordions from "../Accordion/AccApp";
import { faqs, staffContactCards } from "../data/ApplicationData";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";

function Contact() {
  const { section } = useParams();

  useEffect(() => {
    // Scroll to the section based on the 'section' variable
    const sectionElement = document.getElementById(section);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  }, [section]);

  return (
    <div>
      <div id="contact-page">
        {/* Banner Image */}
        <header className="contact-header">
          <div className="contact-header-container">
            <span className="text-2xl text-blue-700 mb-4">Contact</span>
            <h1>Gateway School</h1>
            <div className="social-container">
              <Link
                to="https://www.facebook.com/profile.php?id=100084715140084"
                target="blank"
              >
                <FaFacebook className="w-6 h-6 text-blue-700" />
              </Link>
              <FaInstagram className="w-6 h-6 text-pink-700" />
              <FaTwitter className="w-6 h-6 text-blue-400" />{" "}
            </div>
          </div>
        </header>
        {/* CONTACT DETAILS */}
        <section id="contact-details">
          <div className="contact-details-container">
            <div>
              <FaLocationArrow className="w-12 h-12 text-yellow-500" />
              <div>
                <p className="text">Address</p>
                <p>
                  81c Fort Street, <br />
                  Off Circular Rd. Freetown
                </p>
              </div>
            </div>
            <div>
              <FaPhoneAlt className="w-12 h-12 text-green-500" />
              <div>
                <p className="text">Phone</p>
                <p>00 (232) 79 117795</p>
                <p>00 (232) 76 824084</p>
                <p>00 (232) 78 169527</p>
              </div>
            </div>
            <div>
              <GrMail className="w-12 h-12 text-blue-500" />
              <div>
                <p className="text">Email</p>
                <p>info@gatewayschool-sierraleone.org</p>
              </div>
            </div>
            <div>
              <BiTimeFive className="w-12 h-12 text-orange-500" />
              <div>
                <p className="text">Opening Hours</p>
                <p>8:00 AM - 5:00 PM</p>
              </div>
            </div>
          </div>
        </section>

        {/* CONTACT CARDS */}
        <section id="contact-cards">
          <div className="cards-container">
            <Accordion defaultIndex={[1]} allowMultiple>
              <AccordionItem>
                <h2 className="px-0 bg-gray-500 text-white rounded-md mb-4">
                  <AccordionButton>
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontWeight="bold"
                      fontSize="1.5rem"
                    >
                      Staff Contact Cards
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <div className="flex gap-4 flex-wrap">
                    {staffContactCards.map((card, i) => (
                      <article
                        key={i}
                        className="card flex justify-between flex-wrap flex-grow items-start gap-4 p-4 bg-white shadow-md rounded-lg mb-4 border"
                      >
                        <img
                          src={card.profilePhoto}
                          alt={card.name}
                          className="profile-photo w-16 h-16 rounded-full object-cover"
                        />
                        <div className="details flex-grow">
                          <p>{card.name}</p>
                          <p className="flex gap-1 items-end">
                            <BiMailSend />
                            {card.email}
                          </p>
                          <p>Ph: {card.phone}</p>
                          <p>Class: {card.class}</p>
                        </div>
                      </article>
                    ))}
                  </div>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2 className="px-0 bg-gray-500 text-white rounded-md mb-4">
                  <AccordionButton>
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontWeight="bold"
                      fontSize="1.5rem"
                    >
                      Upper Management Contact
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <div className="flex gap-4 flex-wrap">
                    {staffContactCards.map((card, i) => (
                      <article
                        key={i}
                        className="card flex justify-between flex-wrap items-start gap-4 p-4 bg-white shadow-md rounded-lg mb-4 border"
                      >
                        <img
                          src={card.profilePhoto}
                          alt={card.name}
                          className="profile-photo w-16 h-16 rounded-full object-cover"
                        />
                        <div className="details flex-grow">
                          <p>{card.name}</p>
                          <p className="flex gap-1 items-end">
                            <BiMailSend />
                            {card.email}
                          </p>
                          <p>Ph: {card.phone}</p>
                          <p>Class: {card.class}</p>
                        </div>
                      </article>
                    ))}
                  </div>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </section>

        {/* FAQ's */}
        <section id="faq">
          <div className="faq-container">
            <h1 className="text-4xl text-center mb-16">
              Frequently Asked Questions
            </h1>
            <Accordions faqs={faqs} />
          </div>
        </section>
        {/* Google Maps */}
        <section id="google-map">
          <div className="map-container">
            <h1>Visit Us At This Location</h1>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d967.7314567322055!2d-13.23494026543523!3d8.481954537967058!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xf04c3ccd63c3e69%3A0x9a14e86a41bcc908!2sGateway%20Daycare%2C%20Nursery%20%26%20Preparatory%20School!5e0!3m2!1sen!2sus!4v1697473744936!5m2!1sen!2sus"
              width="600"
              height="650"
              className="map"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          {/* Social Media Links */}
        </section>
      </div>
    </div>
  );
}

export default Contact;
