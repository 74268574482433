function UnderConstruction() {
  return (
    <div className="construction w-full">
      <img src="/gateway_logo_200x200.png" />
      <h1 className="text-2xl font-semibold sm:text-md">
        Gateway Daycare, Nursery and Preparatory School
      </h1>
      <h2 className="text-4xl text-red-700 font-bold">In Development</h2>
      <p>Coming Soon...</p>
    </div>
  );
}

export default UnderConstruction;
