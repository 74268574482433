import { useState } from "react";
import "./Gallery.css";
import Testimonials from "./Testimonials";
import { galleryImages, images } from "../data/ApplicationData";
import GalleryModal from "./GalleryModal";

import Slider from "react-slick";

function Gallery() {
  return (
    <section id="gallery">
      <div className="main-container">
        <header>
          <h2>Life at Gateway</h2>
          <p>
            At Gateway School, life is a vibrant tapestry of learning,
            friendship, and personal growth. We foster an environment where
            every pupil is encouraged to explore their potential, discover their
            passions, and excel academically. Explore the voices of our pupils
            and get a glimpse of the enriching journey they embark on at Gateway
            School.
          </p>
        </header>

        <GallerySlider />
        <div className="gallery-main-container">
          {/* <GalleryModal images={galleryImages} /> */}
        </div>
      </div>
    </section>
  );
}

export default Gallery;

export function GallerySlider() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    // cssEase: "linear",
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div>
          <img src={galleryImages[0]} />
        </div>
        <div>
          <img src={galleryImages[1]} />
        </div>
        <div>
          <img src={galleryImages[2]} />
        </div>
        <div>
          <img src={galleryImages[3]} />
        </div>
        <div>
          <img src={galleryImages[4]} />
        </div>
        <div>
          <img src={galleryImages[5]} />
        </div>
        <div>
          <img src={galleryImages[6]} />
        </div>
        <div>
          <img src={galleryImages[7]} />
        </div>
      </Slider>
    </div>
  );
}
