import {
  adminStaffData,
  leadershipProfileData,
  teacherStaffData,
} from "../data/ApplicationData";
import "./AboutAdministration.css";

function AboutAdministration() {
  return (
    <section>
      <article className="leadership">
        <h1>Leadership & Management</h1>
        <div className="profile-container">
          {leadershipProfileData.map((data, index) => (
            <figure key={index}>
              <img src={data.image} alt={`${data.title} img`} />
              <figcaption>
                <span>{data.title}</span>, {data.role}
              </figcaption>
            </figure>
          ))}
        </div>
      </article>
      <article className="staff">
        <h1>Administration</h1>
        <div className="profile-container">
          {adminStaffData.map((data, index) => (
            <figure key={index}>
              <img src={data.image} alt={`${data.title} img`} />
              <figcaption>
                <span>{data.title}</span>, {data.role}
              </figcaption>
            </figure>
          ))}
        </div>
      </article>
      <article className="staff">
        <h1>Senior Staff</h1>
        <div className="profile-container">
          {teacherStaffData.map((data, index) => (
            <figure key={index}>
              <img src={data.image} alt={`${data.title} img`} />
              <figcaption>
                <span>{data.title}</span>, {data.role}
              </figcaption>
            </figure>
          ))}
        </div>
      </article>
      <article className="staff"></article>
    </section>
  );
}

export default AboutAdministration;
