import { BiChevronDown, BiChevronRight, BiChevronUp } from "react-icons/bi";
import "./AccordionStyles.css";
import { useState } from "react";

export default function Accordions({ faqs }) {
  const [curOpen, setCurOpen] = useState(null);

  return (
    <div className="accordion">
      <div className="accordion-item">
        {faqs.map((faq, i) => (
          <AccordionItem
            curOpen={curOpen}
            onOpen={setCurOpen}
            title={faq.title}
            num={i}
            key={faq.title}
          >
            {/* {faq.text} */}

            <p dangerouslySetInnerHTML={{ __html: faq.text }} />
          </AccordionItem>
        ))}
      </div>
    </div>
  );
}

function AccordionItem({ num, title, onOpen, curOpen, children }) {
  const isOpen = num === curOpen;

  function handleToggle() {
    onOpen(isOpen ? null : num);
  }

  return (
    <div className={`item ${isOpen ? "open" : ""}`}>
      <div onClick={handleToggle}>
        <p className="number">{num < 9 ? `0${num + 1}` : num + 1}</p>
        <p className="title">{title}</p>
        <p className="icon">
          {isOpen ? (
            <BiChevronUp className="w-8 h-12" />
          ) : (
            <BiChevronRight className="w-8 h-12" />
          )}
        </p>
      </div>

      {isOpen && <div className="content-box">{children}</div>}
    </div>
  );
}
