import AppRouter from "./components/AppRouter";
import { SubpageProvider } from "./Contexts/SubpageContext";
import ScrollToTop from "./components/ScrollToTop";

// 1. import `ChakraProvider` component
import { ChakraProvider } from "@chakra-ui/react";

function App() {
  return (
    <div>
      <ChakraProvider>
        <SubpageProvider>
          <AppRouter></AppRouter>
        </SubpageProvider>
      </ChakraProvider>
    </div>
  );
}

export default App;
